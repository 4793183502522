<template>
  <div>
      <vue-header title="评价"/>
      <div class="sub-box">
          <div class="sub-input">
              <div class="flex">
                  <div class="sub-title">
                      服务态度：
                 </div>
                 <div class="flex-1">
                     <van-rate v-model="value" :size="25" />
                 </div>
              </div>
          </div>
          <div class="sub-input">
              <div class="flex">
                  <div class="sub-title">
                      服务专业度：
                 </div>
                 <div class="flex-1">
                     <van-rate v-model="value2" :size="25" />
                 </div>
              </div>
          </div>
          <div class="sub-input">
              <div class="flex">
                  <div class="sub-title">
                      服务环境：
                 </div>
                 <div class="flex-1">
                     <van-rate v-model="value3" :size="25" />
                 </div>
              </div>
          </div>
          <div class="sub-input">
              <div>
                  <div class="sub-title">
                      服务效果：
                 </div>
                 <div>
                     <van-radio-group v-model="radio">
                        <van-radio name="完全消除">完全消除</van-radio>
                        <van-radio name="大幅改善">大幅改善</van-radio>
                        <van-radio name="中度改善">中度改善</van-radio>
                        <van-radio name="轻度改善">轻度改善</van-radio>
                        <van-radio name="症状减少">症状减少</van-radio>
                        <van-radio name="未改善">未改善</van-radio>
                        <van-radio name="症状减多">症状减多</van-radio>
                        <van-radio name="程度加重">程度加重</van-radio>
                    </van-radio-group>
                 </div>
              </div>
          </div>
          <div class="sub-input">
              <div>
                  <div class="sub-title">
                      其他问题：
                 </div>
                 <div class="sub-textarea">
                     <textarea v-model="effect" />
                 </div>
              </div>
          </div>
           <button class="sub-sumbit" @click="sumbit">提交</button>
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions } from 'vuex';
import { titleFun } from '@/utils';
import { Notify } from 'vant';
export default {
  name: 'assessInfo',
  data(){
    return{
      value: 0,
      value2: 0,
      value3: 0,
      radio:'',
      effect:''
    }
  },
  components:{
    vueHeader
  },
  created:function(){
      titleFun('评价');
  },
  methods:{
      sumbit(){
          let bool = true;
          if(!this.value){
              Notify({ type: 'danger', message: '请给服务态度打分！' });
              bool = false;
          }else if(!this.value2){
              Notify({ type: 'danger', message: '请给服务专业度打分！' });
              bool = false;
          }else if(!this.value3){
              Notify({ type: 'danger', message: '请给服务环境打分！' });
              bool = false;
          }else if(!this.radio){
              Notify({ type: 'danger', message: '请选择服务效果！' });
              bool = false;
          }
          if(bool){
              this.assessAddMain({
                  data:{
                      set_meal_use_service_id:Number(this.$route.params.id),
                      taidu_star:this.value,
                      zhuanye_star:this.value2,
                      huanjing_star:this.value3,
                      xiaoguo:this.radio,
                      desc:this.effect
                  },
                  _this:this
              })
          }
      },
      ...mapActions('my',['assessAddMain'])
  }
}
</script>

<style lang="scss" scoped>
  @import './assessInfo';
</style>